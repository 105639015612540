// @ts-nocheck
import MetaMaskOnboarding from "@metamask/onboarding";
import { ethers } from "ethers";
import React, { useEffect } from "react";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import "./Connect.css";

class Connect {
  state = {
    connectText: "Connect Wallet",
    isConnected: "",
    isLoading: false,
  };
  public apiPath = "https://metaportal.api.metamundo.co/v1";
  public accounts = [];
  public signature = null;
  public swapToken = null;
  public jwtToken = null;
  public swapData: any = null;
  public tokenIds = null;
  public connector = null;
  constructor() {
    const { ethereum } = window as any;
    this.ethereum = ethereum;
  }
  ethereum: any = null;
  noonce: any = null;

  componentDidMount() {
    const etheriumConnectionCheck = async () => {
      try {
        const newAccounts = await this.ethereum.request({
          method: "eth_accounts",
        });
        if (newAccounts.length > 0) {
          this.accounts = newAccounts;
          this.checkJwt();
        }

        console.log(newAccounts);
      } catch (error) {
        console.error(error);
      }
    };
    this.isMetaMaskInstalled() && etheriumConnectionCheck();
  }

  isMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  isMetaMaskInstalled() {
    return Boolean(this.ethereum && this.ethereum.isMetaMask);
  }

  checkJwt() {
    if (localStorage.getItem("mm_token")) {
      this.setState({ connectText: "Connected" });
      this.setState({ isConnected: "connected" });
      this.setState({ isLoading: false });
    }
  }

  public walletResolve: any;
  public walletReject: any;
  public swapResolve: any;
  public swapReject: any;

  initialize() {
    const currentUrl = new URL(window.location.href);
    const forwarderOrigin =
      currentUrl.hostname === "localhost" ? "http://localhost:9010" : undefined;
    const onboarding = new MetaMaskOnboarding({ forwarderOrigin });
    const { ethereum } = window as any;
    this.ethereum = ethereum;
    let accounts: any = this.accounts;

    if (this.isMobile()) {
      this.walletConnector();
    } else {
      const isMetaMaskConnected = () =>
        this.accounts && this.accounts.length > 0;

      const MetaMaskClientCheck = () => {
        if (!this.isMetaMaskInstalled()) {
          onboarding.startOnboarding();
        } else if (isMetaMaskConnected()) {
          if (onboarding) {
            onboarding.stopOnboarding();
            etheriumConnect();
          }
        } else {
          etheriumConnect();
        }
      };

      const etheriumConnect = async () => {
        const chainId = await ethereum.request({ method: "eth_chainId" });
        ethereum.on("chainChanged", (chainId) => {
          window.location.reload();
        });
        if (chainId !== "0x1") {
          return this.walletReject({ type: "chain" });
        }

        try {
          const newAccounts = await ethereum.request({
            method: "eth_requestAccounts",
          });
          this.accounts = newAccounts;

          this.getNoOnce(newAccounts);
        } catch (error) {
          console.error(error);
        }
      };

      MetaMaskClientCheck();
    }
    let self = this;

    return new Promise(function (resolve, reject) {
      self.walletResolve = resolve;
      self.walletReject = reject;
    });
  }

  async walletConnector() {
    // Create a connector
    const connector = await new WalletConnect({
      bridge: "https://bridge.walletconnect.org", // Required
      qrcodeModal: QRCodeModal,
    });
    this.connector = connector;

    // Check if connection is already established
    if (!connector.connected) {
      // create new session
      await connector.createSession();
    }
    if (connector.connected) {
      const { chainId, accounts } = connector;
      if (chainId !== 1) {
        connector.killSession();
        return this.walletReject({ type: "chain" });
      }
      this.accounts = accounts;
      this.getNoOnce(accounts);
    }
    // Subscribe to connection events
    connector.on("connect", (error, payload) => {
      if (error) {
        throw error;
      }

      // Get provided accounts and chainId
      const { accounts, chainId } = payload.params[0];
      if (chainId !== 1) {
        connector.killSession();
        return this.walletReject({ type: "chain" });
      }
      this.accounts = accounts;

      this.getNoOnce(accounts);
    });

    connector.on("session_update", (error, payload) => {
      if (error) {
        throw error;
      }
      // Get updated accounts and chainId
      const { accounts, chainId } = payload.params[0];
    });

    connector.on("disconnect", (error, payload) => {
      if (error) {
        throw error;
      }

      // Delete connector
    });
  }

  getNoOnce = async (account: Array<String>) => {
    fetch(`${this.apiPath}/wallets`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        address: account[0],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.noonce = data.nonce;
          this.Sign(data.sign_message);
        }
      });
  };

  Sign = (message) => {
    var method = "personal_sign";
    const msg = message;
    var address = this.accounts[0];
    var params = [msg, address];

    if (this.isMobile()) {
      const msgParams = [
        msg,
        //convertUtf8ToHex(msg), // Required
        address, // Required
      ];
      this.connector
        .signPersonalMessage(msgParams)
        .then((result) => {
          // Returns signature.
          this.signature = result;
          this.getJWT();
          console.log(result);
        })
        .catch((error) => {
          // Error returned when rejected
          return this.walletReject(error);
        });
    } else {
      this.ethereum.sendAsync(
        {
          method,
          params,
          address,
        },
        (err: any, result: any) => {
          if (err) {
            result.type = "sign";
            return this.walletReject(result);
          }
          if (result.error) {
            return this.walletReject(result);
          }
          this.signature = result.result;
          this.getJWT();
        }
      );
    }
  };

  getJWT = () => {
    let address = this.accounts[0];
    let signature = this.signature;
    fetch(`${this.apiPath}/metaportal/teleport/auth`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        address: address,
        signature: signature,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          // this.walletReject(response);
          return response.text().then((text) => {
            throw new Error(JSON.parse(text).error);
          });
        }
        return response.json();
      })
      .then((data) => {
        this.swapToken = data;
        this.jwtToken = data.token;
        this.walletResolve(data);
        this.saveTokens(address, data.token);
      })
      .catch((data) => {
        this.walletReject(data);
      });
  };

  //   refreshJWT() {
  //     let refresh_token = localStorage.getItem("mm_refresh_token");
  //     fetch(`${this.apiPath}/auth/refresh`, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         refresh_token: refresh_token,
  //       }),
  //     })
  //       .then((response) => {
  //         if (!response.ok) {
  //           alert("something wrong next is debugger");
  //           throw new Error("Network response was not ok");
  //         }
  //         return response.json();
  //       })
  //       .then((data) => {
  //         this.setState({ connectText: "Connected" });
  //         this.setState({ isConnected: "connected" });
  //         this.saveTokens(data.token, data.refresh_token);
  //         this.setState({ isLoading: false });
  //       })
  //       .catch((error) => {
  //         this.setState({ isLoading: false });
  //       });
  //   }

  saveTokens(address: string, token: string) {
    //localStorage.setItem("mm_address", address);
    //localStorage.setItem("mm_token", token);
  }

  async switchNetwork() {
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }],
    });
  }

  signOrder(quantity) {
    fetch(`${this.apiPath}/metaportal/redeem`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.jwtToken,
      },
      body: JSON.stringify({
        quantity: quantity,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        this.swapData = data;
        this.swapNft();
      })
      .catch((data) => {
        this.swapReject(data);
      });
  }

  async swapNft() {
    const provider = new ethers.providers.Web3Provider(this.ethereum, "any");
    const gasPrice = (await provider.getGasPrice()).mul(2);
    const assetsToSwapUserA = this.swapData.ERC1155;
    const assetsToSwapUserB = this.swapData.ERC721;
    this.tokenIds = this.swapData.token_ids;
    const self = this;
    const CHAIN_ID = 4;

    const signer = await provider.getSigner();
    const makerAddress = await signer.getAddress();
    const takerAddress = "0x329F61CAEFEa1dF01C60D7D0eafD9CdAe91C853C";
    const nftSwapSdk = new NftSwapV3(provider, signer, CHAIN_ID);

    const approvalStatusForUserA = await nftSwapSdk.loadApprovalStatus(
      assetsToSwapUserA[0],
      makerAddress
    );

    console.log(approvalStatusForUserA);

    if (!approvalStatusForUserA.contractApproved) {
      const approvalTx = await nftSwapSdk
        .approveTokenOrNftByAsset(assetsToSwapUserA[0], makerAddress, {
          gasPrice: gasPrice._hex,
        })
        .then(
          function (data) {},
          function (data) {
            let errorMessage = {};
            if (data.code == "UNPREDICTABLE_GAS_LIMIT") {
              errorMessage.message = "Insufficient ETH to cover gas costs";
            }
            return self.swapReject(errorMessage);
          }
        );
      if (!approvalTx) {
        return;
      }
      const approvalTxReceipt = await approvalTx.wait();
      console.log(
        `Approved ${assetsToSwapUserA[0].tokenAddress} contract to swap with 0x (txHash: ${approvalTxReceipt.transactionHash})`
      );
    }

    const order = nftSwapSdk.buildOrder(
      assetsToSwapUserA,
      assetsToSwapUserB,
      makerAddress,
      {
        takerAddress,
        feeRecipientAddress: "0x0000000000000000000000000000000000000000",
      }
    );

    // Sign the order (User A signs since they are initiating the trade)

    const signedOrder = await nftSwapSdk.signOrder(order, takerAddress).then(
      function (data) {},
      function (data) {
        return self.swapReject(data);
      }
    );
    if (!signedOrder) {
      return;
    }
    await this.startSwapNFT(JSON.stringify(signedOrder));
  }

  async startSwapNFT(order) {
    console.log("signed order", order);

    fetch(`${this.apiPath}/metaportal/swap`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.jwtToken,
      },
      body: JSON.stringify({
        token_ids: this.tokenIds,
        signature: JSON.parse(order),
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        this.checkSwapStatus(data);
      })
      .catch((data) => {
        console.log(data);
        this.swapReject(data);
      });
  }

  checkSwapStatus(swapData) {
    let self = this;
    const intervalValue = setInterval(
      async function () {
        fetch(`${self.apiPath}/metaportal/swap/${swapData.uuid}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + self.jwtToken,
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            if (data.status !== "processing") {
              clearInterval(intervalValue);
              self.swapResolve(data);
            }
          })
          .catch((error) => {
            console.log(error);
            self.swapReject(error);
          });
      }.bind(this),
      3000
    );
  }

  startSwap(quantity) {
    this.signOrder(quantity);
    let self = this;
    return new Promise(function (resolve, reject) {
      self.swapResolve = resolve;
      self.swapReject = reject;
    });
  }

  render() {
    return (
      <button
        className={"btn btn-dark btn-gradient " + this.state.isConnected}
        onClick={() => {
          this.initialize();
        }}
      >
        {this.state.isLoading && (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        {this.state.connectText}

        {this.accounts && (
          <div className="account_address">{this.accounts[0]}</div>
        )}
      </button>
    );
  }
}

export default Connect;
