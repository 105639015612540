import { createContext } from 'react';

const UserContext = createContext(
    {
        address: false,
        setAddress: (auth) => {},
        token:false,
        setToken:(token)=>{},
        user: null,
        setUser:(data)=>{}
    }
  );
  
export default UserContext;