import "./Teleport.css";
import React from "react";
import axios from "axios";
import Connect from "../../api/connect/Connect";
import { Spinner, Modal } from "react-bootstrap";

class Teleport extends React.Component<any, any> {
  ModalStates = {
    NONFT: 2,
    LOADER: 1,
    SUCCESS: 3,
  };
  state = {
    modalopen: false,
    modalState: this.ModalStates.LOADER,
    modalMessage: "",
    errorMessage: "",
    successMessage: "",
    notMainNet: false,
  };
  public connect = new Connect();
  onConnect() {
    this.setState({ modalopen: true });
    this.setState({ modalState: this.ModalStates.LOADER });
    this.connect.initialize().then(
      (data: any) => {
        this.closeModal();
        this.setState({ errorMessage: "" });
        this.setState({
          successMessage: "Teleporting you into an exclusive experience…",
        });
        // if (this.connect.isMobile()) {
        setTimeout(function () {
          window.location.href = data.redirect_to;
        }, 10);
        // } else {
        //   setTimeout(function () {
        //     window.open(data.redirect_to);
        //   }, 10);
        // }
      },
      (data) => {
        this.closeModal();
        if (data.type == "chain") {
          this.closeModal();
          this.setState({ notMainNet: true });
          return;
        }

        const message =
          data.message || "Something went wrong, Please try again";
        this.setState({ errorMessage: message });
      }
    );
  }
  renderModal() {
    switch (this.state.modalState) {
      case 1:
        return this.loaderModal();
      case 2:
        this.setState({
          modalMessage:
            "You don’t have any MetaPortal NFTs to redeem in your wallet",
        });
        return this.noNftModal();
      case 3:
        this.setState({
          modalMessage: "Teleporting you into an exclusive experience…",
        });
        return this.noNftModal();
      default:
        return this.loaderModal();
    }
  }

  noNftModal() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="demibold">
            {this.state.modalMessage}
          </Modal.Title>
        </Modal.Header>
      </>
    );
  }

  loaderModal() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="demibold">
            {<div>Please sign the message in your wallet to continue </div>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="loader-modal">
          <div className="redemption-spinner">
            <Spinner animation="border" />
          </div>
          <div className="redemption-body centered">
            {
              <>
                MetaMundo uses this signature to verify that you’re the owner of
                the MetaMundo address.
              </>
            }
          </div>
        </Modal.Body>
      </>
    );
  }
  switchNetwork() {
    this.connect.switchNetwork();
  }

  closeModal = () => {
    this.setState({ modalopen: false });
  };
  render() {
    return (
      <div className="mm-teleport">
        {this.state.notMainNet && (
          <div
            className="notMainNet"
            onClick={() => {
              this.switchNetwork();
            }}
          >
            Please connect Ethereum mainnet to continue.{" "}
            {this.connect.isMobile() ? (
              <> Change network and reload this page to continue</>
            ) : (
              <>Click here to switch network.</>
            )}
          </div>
        )}
        <div className="portal">
          <h1>Teleporting you into a unique experience…</h1>
          <div>
            In order to teleport you into the adventure that awaits, we need to
            quickly verify your ownership of the MetaPortal. Connect your wallet
            for verification.
          </div>
          <a
            id="connect-wallet"
            onClick={() => {
              this.onConnect();
            }}
            className="demibold btn darkbtn"
          >
            Connect Wallet
          </a>
          {this.state.errorMessage && (
            <div className="modalError">{this.state.errorMessage}</div>
          )}
          {this.state.successMessage && (
            <div className="modalSuccess">{this.state.successMessage}</div>
          )}
        </div>
        <Modal
          show={this.state.modalopen}
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {this.renderModal()}
        </Modal>
      </div>
    );
  }
}

export default Teleport;
