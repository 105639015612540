// @ts-nocheck
import Header2 from './components/header2/Header2';
import Teleport from './components/teleport/Teleport';
import GoogleAnalytics from './api/googleAnalytics/GoogleAnalytics';
import { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './Mobile.css';

function App() {

  const [address, setAddress] = useState(false);
  const [token, setToken] = useState(false);
  const [user, setUser] = useState(false);
  return (
    <div className="App">
      <Router>
        <Header2></Header2>
        <Teleport></Teleport>
        <GoogleAnalytics />
    </Router>
    </div >
  );
}

export default App;
